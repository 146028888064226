<template>
  <div class="problem" style="display:flex;">
      <el-form style="width:500px">
        <el-form-item label="点位：">
          <el-text type="primary">{{props.problem.pointPositionName}}</el-text>
        </el-form-item>
        <el-form-item label="标准：">
          <el-text>{{props.problem.standardName}}</el-text>
        </el-form-item>
        <el-form-item label="标准描述：">
          <el-text>{{props.problem.standardInfo.problemDescribe}}</el-text>
        </el-form-item>
        <el-form-item label="标签：">
          <!-- <el-tag type="success" v-if="props.problem.labelFirst">{{props.problem.labelFirst}}</el-tag> -->
          <el-tag type="warning" v-if="props.problem.labelSecond">{{props.problem.labelSecond}}</el-tag>
          <!-- <el-tag type="default" v-if="props.problem.labelThird">{{props.problem.labelThird}}</el-tag> -->
        </el-form-item>
        <el-form-item label="问题上报时间：" v-if="props.problem.reportTime">
          <el-text>{{props.problem.reportTime}}</el-text>
        </el-form-item>
         <el-form-item label="审核时间：" v-if="props.problem.checkTime">
          <el-text>{{props.problem.checkTime}}</el-text>
        </el-form-item>
        <el-form-item label="状态：">
            <template v-for="item in data.problemStatusList">
              <el-tag :type="item.type" :key="item.index" v-if="item.value == props.problem.problemStatus">{{ item.label }}</el-tag>
          </template>
        </el-form-item>
        <el-form-item label="审核驳回理由" v-if="props.problem.checkReason">
          <el-text>{{props.problem.checkReason}}</el-text>
        </el-form-item>
        <el-form-item label="是否反复：" v-if="props.problem.hasRepeat">
          <el-text v-if="props.problem.hasRepeat==true" type="primary">是</el-text>
          <el-text v-else type="warning">否</el-text>
        </el-form-item>
        <el-form-item label="问题程度：" v-if="props.problem.problemLevel">
          <el-text>{{props.problem.problemLevel}}</el-text>
        </el-form-item>
        <el-form-item label="问题描述：" v-if="props.problem.problemDescribe">
          <el-text>{{props.problem.problemDescribe}}</el-text>
        </el-form-item>
        <el-form-item label="整改初审通过时间：" v-if="props.problem.rectifyFirstCheckTime">
          <el-text>{{props.problem.rectifyFirstCheckTime}}</el-text>
        </el-form-item>
        <el-form-item label="整改终审通过时间：" v-if="props.problem.rectifyEndCheckTime">
          <el-text>{{props.problem.rectifyEndCheckTime}}</el-text>
        </el-form-item>
        <el-form-item label="驳回次数" v-if="props.problem.rejectNum">
          <el-text>{{props.problem.rejectNum}}</el-text>
        </el-form-item>
        <el-form-item label="分值：" v-if="props.problem.score">
          <el-text type="danger">{{props.problem.score}}分</el-text>
        </el-form-item>
      </el-form>
      <el-form style="width:500px">
         <el-form-item label="问题记录" v-if="data.rectifyList.length>0">
          <el-timeline>
             <el-timeline-item  placement="top" :timestamp="'上报问题：'+props.problem.reportTime">
                <el-image
                  v-for="(pic, index) in props.problem.problemPicsArray"
                  :key="index"
                  style="width: 100px; height: 100px; margin-right: 20px;"
                  :src="pic"
                  :zoom-rate="1.2"
                  :max-scale="7"
                  :min-scale="0.2"
                  :preview-src-list="props.problem.problemPicsArray"
                  :initial-index="index"
                  fit="cover"
                />
                <span v-if="props.problem.problemPicsArray.length==0">未上传问题图片</span>
            </el-timeline-item>
            <el-timeline-item  placement="top" v-for="(item, index) in data.rectifyList" :timestamp="'提交整改：'+item.rectifyTime" 
            :key="index">
         
              <el-image
                    v-for="(item2, index2) in item.rectifyPics"
                    :key="index2"
                    style="width: 100px; height: 100px; margin-right: 20px;"
                    :src="item2"
                    :zoom-rate="1.2"
                    :max-scale="7"
                    :min-scale="0.2"
                    :preview-src-list="item.rectifyPics"
                    :initial-index="index2"
                    fit="cover"
                  />
                  <div>
                    <span v-if="item.rectifyUserName">整改员：{{item.rectifyUserName}}<br></span>
                    <span v-if="item.memo">留言：{{item.memo}}<br></span>
                    <span v-if="item.rectifyCheckUserName">审核员：{{item.rectifyCheckUserName}}</span>
                    <template v-for="(item3,index) in data.rectifyCheckStatusList" >
                      <el-tag  :key="index" v-if="item.rectifyCheckStatus === item3.value" :type="item3.type">{{item3.label}}</el-tag>
                    </template>
                    <span v-if="item.rejectReason"><br>驳回理由：{{item.rejectReason}}</span>
                  </div>
            </el-timeline-item>
            <el-timeline-item  placement="top">
            </el-timeline-item>
          </el-timeline>
        </el-form-item>
        <el-form-item v-else label="问题图片：">
          <el-image
                  v-for="(pic, index) in props.problem.problemPicsArray"
                  :key="index"
                  style="width: 100px; height: 100px; margin-right: 20px;"
                  :src="pic"
                  :zoom-rate="1.2"
                  :max-scale="7"
                  :min-scale="0.2"
                  :preview-src-list="props.problem.problemPicsArray"
                  :initial-index="index"
                  fit="cover"
                />
                <span v-if="props.problem.problemPicsArray.length==0">未上传问题图片</span>
          </el-form-item>
        
      </el-form>
  </div>
</template>
  
<script setup>
import { defineProps,reactive} from 'vue'
import api from "../apis/api";
const data = reactive({
  problemStatusList: [
    {
      label: "考核通过",
      value: "checkPass",
      type:"success"
    },
    {
      label: "待审核",
      value: "waitCheck",
      type:"info"
    },
    {
      label: "审核不通过",
      value: "checkUnPass",
      type:"danger"
    },
    {
      label: "待整改",
      value: "waitRectify",
      type:"info"
    },
    {
      label: "整改待初审",
      value: "waitFirstReview",
      type:"primary"
    },
    {
      label: "初审被驳回",
      value: "firstReject",
    },
    {
      label: "待终审",
      value: "waitEndReview",
      type:"warning"
    },
    {
      label: "整改通过",
      value: "reviewPass",
      type:"success"
    },
    {
      label: "终审被驳回",
      value: "endReject",
    },
  ],
  rectifyList:[],
  now:new Date().toLocaleString(),
  rectifyCheckStatusList:[
  {
      label: "审核通过",
      value: "reviewPass",
      type:"success"
    },
    {
      label: "取消",
      value: "cancel",
      type:"warning"
    },
    {
      label: "整改待初审",
      value: "waitFirstReview",
      type:"info"
    },
    {
      label: "初审被驳回",
      value: "firstReject",
      type:"danger"
    },
    {
      label: "整改待终审",
      value: "waitEndReview",
      type:"info"
    },
    {
      label: "终审被驳回",
      value: "endReject",
      type:"danger"
    },
  ]
})
const props = defineProps(['problem'])
api.get("/backend/problem/findRectify", { params: { id: props.problem.id } }).then(res => {
  for(let i=0;i<res.list.length;i++){
    if(res.list[i].rectifyPics){
      res.list[i].rectifyPics = JSON.parse(res.list[i].rectifyPics);
    }
  }
  data.rectifyList = res.list;

});


</script>